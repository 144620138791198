function iosDownload() {
    window.location.href = `itms-services:///?action=download-manifest&url=https://pili-vod.yixyy.cn/app/mile2.plist?${Date.now()}`;
}
function androidDownload() {
    window.location.href = "http://pili-vod.yixyy.cn/app/huohuo.apk"
}

export {
    iosDownload,
    androidDownload
}
