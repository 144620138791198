export function getUrlParam(arg, arg_name) {
    let query = arg.slice(1)
    var vars = query.split("&");

    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] === arg_name) {
            return pair[1];
        }
    }
    return (false);
}

export function formatNumber(money) {
    if (money === 10000 || money === 100000000) {
        return '1万'
    } else if (money < 100000000) {
        return Math.floor((money / 10000) * 100) / 100
    } else {
        return Math.floor((money / 100000000) * 100) / 100
    }
}

// getRecharge(params).then((res) => {
//     const data = res.data.data
//     console.log(data.apiParam, '接口返回数据', res)
//     window.wx.config({
//       debug: false,
//       appId: data.apiParam.appId,
//       timestamp: data.apiParam.timeStamp,
//       nonceStr: data.apiParam.nonceStr,
//       signature: data.apiParam.paySign,
//       jsApiList: ['chooseWXPay'],
//     })
//     window.wx.ready((res) => {
//       console.log(res, 'weixinReady')
//       window.wx.checkJsApi({
//         jsApiList: ['chooseWXPay'],
//         success: (res) => {
//           console.log('checked api:', res)
//         },
//         fail: (err) => {
//           console.log('check api fail:', err)
//         },
//       })
//     })
//     window.wx.chooseWXPay({
//       // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
//       timestamp: data.apiParam.timeStamp,
//       // 支付签名随机串，不长于 32 位
//       nonceStr: data.apiParam.nonceStr,
//       // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
//       package: data.apiParam.package,
//       // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
//       signType: data.apiParam.signType,
//       // 支付签名
//       paySign: data.apiParam.paySign,
//       // 支付成功后的回调函数
//       success: function (res) {
//         // res.errMsg === 'chooseWXPay:ok'方式判断前端返回,微信团队郑重提示：
//         // res.errMsg将在用户支付成功后返回ok，但并不保证它绝对可靠， 切记。
//         console.log(res, '支付接口返回')
//         // if (res.errMsg === 'chooseWXPay:ok') {
//         //   that.$router.push({
//         //     name: 'ReturnPage',
//         //     query: {
//         //       orderNo: res.data.orderBaseinfo.orderNo
//         //     }
//         //   })
//         // }
//       },
//       // 支付取消回调函数
//       cancel: function (res) {
//         // Toast('用户取消支付~')
//       },
//       // 支付失败回调函数
//       fail: function (res) {
//         // Toast('支付失败~')
//       },
//     })
//   })